import Logo from '../logo.png'

function Footer() {
    return (
        <footer class="bg-gray-900" id="contact">
            <div class="flex flex-col items-center mx-auto w-full max-w-screen-xl py-6 lg:py-8">
                <div className="flex flex-row">
                    <div className="mr-8 flex flex-col items-center">
                        <p className="self-center text-2xl text-white">
                            Leiden Debating Union <br/>
                            Kaiserstraat 25 <br/>
                            2311 GN Leiden <br/>
                            <br/>
                            leidendebatingunion@gmail.com
                        </p>
                    </div>
                    <div className='flex flex-col mb-4'>
                        <a href='https://www.instagram.com/leidendebatingunion/?hl=en'><img className='w-10 m-2'
                                                                                            alt='Instagram'
                                                                                            src={require('../resources/icons/icon-2.png')}></img></a>
                        <a href='https://www.facebook.com/LeidenDebating/'><img className='w-10 m-2' alt='Facebook'
                                                                                src={require('../resources/icons/icon-1.png')}></img></a>
                        <a href='https://www.linkedin.com/company/leiden-debating-union/about/'><img
                            className='w-10 m-2' alt='Linkedin'
                            src={require('../resources/icons/icon-3.png')}></img></a>
                    </div>
                </div>
                <div class="mt-6 mb-6">
                    <a href="#" class="flex content-center">
                        <img src={Logo} class="h-14 mr-3" alt="LDU"/>
                        <span class="self-center text-4xl md:text-6xl font-semibold whitespace-nowrap text-white">Leiden Debating Union</span>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;